import Style from './WinGift.module.css'

const WinGift = () => {
  return (
    <section className={Style.winGift}>
      <div className={Style.winGiftContainer}>
        <div className={Style.backgroundImage}></div>
        <div className={Style.heartShape}>
          <img src="images/yellow-shape.png" alt="Yellow Shape" className={`${Style.yellowShape} d-lg-none`} />
          <div className={Style.formContainer}>
            <h1>Get <strong>20% OFF</strong> your next order & a chance to <strong>WIN Bully Sticks!</strong></h1>
            <p>Enter your email to receive a 20% discount code & automatically enroll to win free bully sticks. <strong>We select a new winner each month.</strong></p>
            <div className={Style.formWrapper}>
              <div id="mc_embed_shell">
                <div id="mc_embed_signup">
                  <form action="https://willowandi.us17.list-manage.com/subscribe/post?u=24be5dd1ccaac8e0c1fad5a29&amp;id=c5f1f8058f&amp;f_id=0099c2e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" rel="noopener noreferrer">
                    <div id="mc_embed_signup_scroll">
                      <div className="mc-field-group">
                        <input
                          type="email"
                          name="EMAIL"
                          className="required email"
                          id="mce-EMAIL"
                          required
                          aria-required="true"
                          aria-label="Your Email Address"
                          placeholder='E-mail address'
                          />
                      </div>
                      <div className="mc-field-group d-flex align-items-start">
                        <input type="checkbox" name="policy" className="checkbox" id="mce-policy" required />
                        <label htmlFor="mce-policy">I agree with the <a target='_blank' rel="noopener noreferrer" href="/giveaway-policy">Giveaway Terms & Conditions</a>, and to receive marketing communication from Willow&I.</label>
                      </div>
                      <div id="mce-responses" className="clear">
                        <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                        <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                      </div>
                      <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                        <input type="text" name="b_24be5dd1ccaac8e0c1fad5a29_c5f1f8058f" tabIndex="-1" />
                      </div>
                      <div className="text-center">
                        <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="CLAIM YOUR DISCOUNT" aria-label="Submit Button"/>
                      </div>
                    </div>
                  </form>
                </div>
                <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
                <script type="text/javascript" dangerouslySetInnerHTML={{
                  __html: `
                  (function($) {
                    window.fnames = new Array(); 
                    window.ftypes = new Array();
                    fnames[0]='EMAIL';
                    ftypes[0]='email';
                    fnames[1]='FNAME';
                    ftypes[1]='text';
                    fnames[2]='LNAME';
                    ftypes[2]='text';
                    fnames[3]='ADDRESS';
                    ftypes[3]='address';
                    fnames[4]='PHONE';
                    ftypes[4]='phone';
                  }(jQuery));
                  var $mcj = jQuery.noConflict(true);
                  `}}></script>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center d-lg-none">
          <img src="images/dog-image.png" alt="Dog with Bully Stick" className={Style.dogImage} />
        </div>
      </div>
    </section>
  );
}

export default WinGift;