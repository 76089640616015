import Style from './Footer.module.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

const Footer = () => {
  return (
    <footer className={Style.footer}>
      <div className="container text-center text-md-start">
        <div className="row">
          <div className="col-12 col-md-2 d-flex align-items-center justify-content-center mb-4 mb-md-0">
            <img src="images/willowi-icon-pantone7479c-1@2x.png" alt="Company logo: Willow & I" />
          </div>
          <div className="col-12 col-md-3 d-flex justify-content-center justify-content-md-start align-items-start mb-4 mb-md-0">
            <ul className="d-flex flex-column align-items-center align-items-md-start">
              <li>
                <span className={Style.footerHeading}>Contact</span>
              </li>
              <li><a href="mailto:hello@willowandi.co"><FontAwesomeIcon icon={faEnvelope}  className={Style.icon}/>hello@willowandi.co</a></li>
              <li><a target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/willowandi.co/?igsh=YXhmb3I3eHBzNHRm&utm_source=qr&fbclid=IwZXh0bgNhZW0CMTAAAR0SEojgvz2T7pGmYzlfanM5fJeuRN7eizUItEGV2qyDcoqI7vE7u-n7wFs_aem_AYajOg93r-htIwQDXpGeNIgZRRnQ8iSDPvinz0cxBABJq7hXr8X25Fp135Gb-IcCUTB1F-3xKE9PEP1Va6ft2OvN"><FontAwesomeIcon icon={faInstagram}  className={Style.icon} />Instagram</a></li>
              <li><a target='_blank' rel="noopener noreferrer" href="https://facebook.com/willowandi.co"><FontAwesomeIcon icon={faFacebookF}  className={Style.icon} />Facebook</a></li>
              <li><a target='_blank' rel="noopener noreferrer" href="https://www.youtube.com/@willowandi"><FontAwesomeIcon icon={faYoutube}  className={Style.icon} />Youtube</a></li>
              <li><a target='_blank' rel="noopener noreferrer" href="https://www.tiktok.com/@willowandico"><FontAwesomeIcon icon={faTiktok}  className={Style.icon} />Tiktok</a></li>
            </ul>
          </div>
          <div className="col-12 col-md-3 d-flex justify-content-md-center justify-content-center align-items-start">
            <ul className="d-flex flex-column align-items-center align-items-md-start">
              <li>
                <span className={Style.footerHeading}>Information</span>
              </li>
              <li><Link to="/about">OUR STORY</Link></li>
              <li><Link to="/products">PRODUCTS</Link></li>
              <li><Link to="/contact">CONTACT US</Link></li>
              <li><Link to="/faq">FAQ</Link></li>
              <li><Link to="/privacy-policy">PRIVACY POLICY</Link></li>
              <li><Link to="/terms-of-use">TERMS OF USE</Link></li>
            </ul>
          </div>
          <div className="col-12 col-md-4 d-flex justify-content-md-center justify-content-center align-items-start">
            <div className="d-flex flex-column align-items-center align-items-md-start w-100">
              <div id="mc_embed_shell-footer" className='w-100'>
                <section aria-label="Email Subscription Form" id="mc_embed_signup">
                  <form action="https://willowandi.us17.list-manage.com/subscribe/post?u=24be5dd1ccaac8e0c1fad5a29&amp;id=83ab82e317&amp;f_id=00d6c2e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate w-100 m-0" target="_blank" rel="noopener noreferrer" aria-label="Email Subscription Form">
                    <div id="mc_embed_signup_scroll-footer">
                      <div className="mc-field-group text-center text-md-start mt-5 mt-md-0">
                        <label>Join the Willow & I pack</label>
                        <input 
                          type="email" 
                          name="EMAIL" 
                          className="required email" 
                          id="mce-EMAIL-footer" 
                          required 
                          aria-required="true"
                          aria-label="Your Email Address"
                          placeholder='E-mail address'
                        />
                      </div>
                      <div id="mce-responses-footer" className="clear">
                        <div className="response" id="mce-error-response-footer" style={{ display: 'none' }}></div>
                        <div className="response" id="mce-success-response-footer" style={{ display: 'none' }}></div>
                      </div>
                      <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                        <input type="text" name="b_24be5dd1ccaac8e0c1fad5a29_83ab82e317" tabIndex="-1" />
                      </div>
                      <div className="clear text-center">
                        <input type="submit" name="subscribe" id="mc-embedded-subscribe-footer" value="SIGN UP" aria-label="Subscribe Button" />
                      </div>
                    </div>
                  </form>
                </section>
                <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
                <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `
                  (function($) {
                    window.fnames = new Array();
                    window.ftypes = new Array();
                    fnames[1]='FNAME';
                    ftypes[1]='text';
                    fnames[0]='EMAIL';
                    ftypes[0]='email';
                    fnames[2]='LNAME';
                    ftypes[2]='text';
                    fnames[3]='ADDRESS';
                    ftypes[3]='address';
                    fnames[4]='PHONE';
                    ftypes[4]='phone';
                    fnames[5]='BIRTHDAY';
                    ftypes[5]='birthday';
                  }(jQuery));
                  var $mcj = jQuery.noConflict(true);
                `}}></script>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
   );
}

export default Footer;